import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './layouts/layouts/main-layout/main-layout.component';
import { EmptyLayoutComponent } from './layouts/layouts/empty-layout/empty-layout.component';

import { AuthGuard } from './core/guards/auth-guard';
import { WebviewAccessResolver } from './core/services/webview-access-.resolver';
import { ReloadComponent } from './reload.component';
import { FeatureResolver } from './feature.resolver';
import { AuthBypassGuard } from './core/guards/auth-bypass-guard';
import { FeaturePublicResolver } from './feature-public.resolver';

const routes: Routes = [
  {
    path: 'reloadComponent',
    component: ReloadComponent,
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    resolve: { empty: FeatureResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('./+home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'all-courses',
        loadChildren: () => import('./+all-courses/all-courses.module').then(m => m.AllCoursesModule),
      },
      {
        path: 'my-courses',
        loadChildren: () => import('./+my-courses/my-courses.module').then(m => m.MyCoursesModule),
      },
      {
        path: 'lecturer-courses',
        loadChildren: () => import('./+lecturer-courses/lecturer-courses.module').then(m => m.LecturerCoursesModule),
      },
      {
        path: 'courses/:id',
        loadChildren: () => import('./+course-detail/course-detail.module').then(m => m.CourseDetailModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./+profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'course-owner',
        loadChildren: () => import('./+course-owner/course-owner.module').then(m => m.CourseOwnerPageModule),
      },
      {
        path: 'announcements',
        loadChildren: () => import('./+announcement/announcement.module').then(m => m.AnnouncementModule),
      }
    ],
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    canActivateChild: [AuthGuard],
    resolve: { empty: FeatureResolver },
    children: [
      {
        path: 'course-owner',
        loadChildren: () => import('./+course-owner/course-owner-no-header.module').then(m => m.CourseOwnerNoHeaderPageModule),
      },
    ],
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    canActivateChild: [AuthBypassGuard],
    resolve: { empty: FeatureResolver },
    children: [
      {
        path: 'courses/:id/lecture/:lectureId',
        resolve: { isWebviewAccess: WebviewAccessResolver },
        loadChildren: () => import('./+lecture/lecture.module').then(m => m.LectureModule),
      },
      {
        path: 'courses/:id/exams',
        resolve: { isWebviewAccess: WebviewAccessResolver },
        loadChildren: () => import('./+exam/exam.module').then(m => m.ExamModule),
      },
      {
        path: 'webview',
        resolve: { isWebviewAccess: WebviewAccessResolver },
        loadChildren: () => import('./webview/webview.module').then(m => m.WebviewModule),
      },
    ],
  },
  {
    path: 'auth',
    resolve: { empty: FeaturePublicResolver },
    loadChildren: () => import('./+auth/auth.module').then(m => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
  providers: [
    FeaturePublicResolver,
    FeatureResolver,
  ],
})
export class AppRoutingModule { }
