/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { AnnouncementDTO } from '../model/announcementDTO';


@Injectable({
  providedIn: 'root',
})
export class AnnouncementsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiAnnouncementsByIdGet$(id: string): Observable<AnnouncementDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Announcements/${encodeURIComponent(String(id))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiAnnouncementsGet$(page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, title?: string, headerResponse?: HeaderResponse): Observable<Array<AnnouncementDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Announcements`, { page, pageSize, sortBy, ascending, title }, false, headerResponse);
  }
}
