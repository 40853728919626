import { Injectable, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

export function sanitizeHtml(html: string): string {
  const sanitized = DOMPurify.sanitize(html, {
    ADD_TAGS: [
      'iframe',
      'big',
    ],
    ADD_ATTR: [
      'contenteditable', 'target', // all
      'frameborder', 'allowfullscreen', // iframe, video
      'cmd', 'key', // button
      'mathquill-block-id', 'mathquill-command-id', 'autocorrect', 'x-palm-disable-ste-all' // mathquill
    ],
    ALLOW_UNKNOWN_PROTOCOLS: true, // capacitor
  });

  // for debugging
  if (DOMPurify.removed.length > 0) {
    window.console.debug('DOMPurify.removed', DOMPurify.removed);
  }

  return sanitized;
}

@Injectable()
export class HtmlSanitizeService implements Sanitizer {

  constructor(
    private domSanitizer: DomSanitizer,
  ) {
  }

  sanitize(context: SecurityContext, value: string | null): string | null {
    if (value && context === SecurityContext.HTML) {
      return sanitizeHtml(value);
    }
    return this.domSanitizer.sanitize(context, value);
  }
}